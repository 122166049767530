// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-index-js": () => import("./../../../src/templates/about-us/index.js" /* webpackChunkName: "component---src-templates-about-us-index-js" */),
  "component---src-templates-capabilities-machine-learning-index-js": () => import("./../../../src/templates/capabilities-machine-learning/index.js" /* webpackChunkName: "component---src-templates-capabilities-machine-learning-index-js" */),
  "component---src-templates-capabilities-mobile-index-js": () => import("./../../../src/templates/capabilities-mobile/index.js" /* webpackChunkName: "component---src-templates-capabilities-mobile-index-js" */),
  "component---src-templates-capabilities-web-index-js": () => import("./../../../src/templates/capabilities-web/index.js" /* webpackChunkName: "component---src-templates-capabilities-web-index-js" */),
  "component---src-templates-careers-index-js": () => import("./../../../src/templates/careers/index.js" /* webpackChunkName: "component---src-templates-careers-index-js" */),
  "component---src-templates-case-index-js": () => import("./../../../src/templates/case/index.js" /* webpackChunkName: "component---src-templates-case-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-full-product-index-js": () => import("./../../../src/templates/full-product/index.js" /* webpackChunkName: "component---src-templates-full-product-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-los-angeles-index-js": () => import("./../../../src/templates/los-angeles/index.js" /* webpackChunkName: "component---src-templates-los-angeles-index-js" */),
  "component---src-templates-nearshore-index-js": () => import("./../../../src/templates/nearshore/index.js" /* webpackChunkName: "component---src-templates-nearshore-index-js" */),
  "component---src-templates-portfolio-index-js": () => import("./../../../src/templates/portfolio/index.js" /* webpackChunkName: "component---src-templates-portfolio-index-js" */),
  "component---src-templates-product-design-index-js": () => import("./../../../src/templates/product-design/index.js" /* webpackChunkName: "component---src-templates-product-design-index-js" */),
  "component---src-templates-product-growth-index-js": () => import("./../../../src/templates/product-growth/index.js" /* webpackChunkName: "component---src-templates-product-growth-index-js" */),
  "component---src-templates-product-strategy-index-js": () => import("./../../../src/templates/product-strategy/index.js" /* webpackChunkName: "component---src-templates-product-strategy-index-js" */),
  "component---src-templates-staff-augmentation-index-js": () => import("./../../../src/templates/staff-augmentation/index.js" /* webpackChunkName: "component---src-templates-staff-augmentation-index-js" */),
  "component---src-templates-summarized-content-index-js": () => import("./../../../src/templates/summarized-content/index.js" /* webpackChunkName: "component---src-templates-summarized-content-index-js" */),
  "component---src-templates-thank-you-index-js": () => import("./../../../src/templates/thank-you/index.js" /* webpackChunkName: "component---src-templates-thank-you-index-js" */),
  "component---src-templates-ux-quiz-index-js": () => import("./../../../src/templates/ux-quiz/index.js" /* webpackChunkName: "component---src-templates-ux-quiz-index-js" */)
}

